<template>
  <div>
    <PanelNavBar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import PanelNavBar from '../components/partials/PanelNavBar.vue';
import Footer from '../components/partials/Footer.vue';
//import PanelFooter from '../components/partials/PanelFooter.vue';

export default {
    components: {
        PanelNavBar, Footer
    },
    metaInfo: {
        titleTemplate: '%s - Harmony Fan',
        title: 'Panel'
    }
};
</script>

<style scoped>
    #app {
        background: #f1eeff !important;
    }
</style>